<template>
  <div>
    <div class="container-fluid" style="margin-top: 71px; position: relative" v-show="showBlock === 'planningResult'">
      <Toast v-if="toastData.show" :type="toastData.type" :message="toastData.message" :show="toastData.show"
        :time="10000" @clearToast="clearToast" />
      <div>
        <ul class="breadcrumb ProximaNovaRegular">
          <li>
            <router-link to="/solutions/acko/platform/allstats">
              <span>Model</span>
            </router-link>
          </li>
          <!-- <li>
            <router-link to="/solutions/digital-poem/stats">
              <span>Sale Statistics</span>
            </router-link>
          </li> -->
          <li>
            <router-link to="/solutions/ul/bbo-results">
              <span>Future Planning</span>
            </router-link>
          </li>
          <li class="ProximaNovaBold">Planning Results</li>
        </ul>
      </div>
      <div class="d-flex mt-4" :class="[expand ? 'justify-content-end' : 'justify-content-between']">
        <div class="w-100 d-flex" style="justify-content: space-between">
          <div class="card-header font-large ProximaNovaBold content-center" style="
              justify-content: space-between;
              background: transparent;
              border: 0px !important;
            ">
            <div class="d-flex" v-if="fromHistoryTable === 'false'">
              <p>Brand - {{ brand }}</p>
              <p class="ml-5">KPI - {{ kpi }}</p>
            </div>
            <div class="d-flex" v-else>
              <p>Brand - {{ optimizeDataTEMP.brand }}</p>
              <p class="ml-5">KPI - Overall</p>
            </div>

            <!-- <div
              class="d-none d-md-block card arrow-card"
              :class="[expand ? 'arrow-card-collapse' : '']"
              @click="toggleExpand"
            >
              <i v-if="!expand" class="fas fa-arrow-left arrow"></i>
              <i v-else class="fas fa-arrow-right arrow-white"></i>
            </div> -->
          </div>

          <div>
            <w-button class="mx-2 btn-effect d-none" @buttonClicked="$refs.savePlanModal.open({ action: 'saveResult' })"
              :buttonText="'Save Plan'" style="width: auto">
            </w-button>

            <!--<w-button
              class="mx-2 btn-effect"
              @buttonClicked="showAgile"
              :buttonText="'Agile Planning'"
              style="width: auto;"
            >
            </w-button>-->
            <div class="d-none" v-if="currentTab === 'bbo'">
              <w-button class="btn-effect-outline" @buttonClicked="gotoScenarioPlan" :buttonLoader="'none'"
                :buttonText="'Scenerio Planning'" style="width: auto">
              </w-button>
              <div v-if="fromScenarioPlanning === 'false'">
                <w-button class="ml-5" :buttonLoader="'none'" :buttonText="'Download'" style="width: auto"
                  @buttonClicked="handleDownload">
                </w-button>
              </div>
            </div>

            <div></div>
            <div class="d-flex flex-row justify-content-end" style="height: 40%">
              <div>
                <!-- <w-white-dropdown
          :options="brandDropdown"
          :placeHolder="'Select'"
          :selectedOption="selectedBrand"
          :labelText="'Brand'"
          class="mr-3"
          @input="selectBrand($event)"
        ></w-white-dropdown> -->
              </div>

              <!-- <div v-if="currentTab === 'forecasting'" class="">
                <w-white-dropdown
                  style="border-radius: 5px; width: 500px"
                  :options="scenarioDropdown"
                  :placeHolder="'Select'"
                  :selectedOption="selectedScenario"
                  :labelText="'Forecast Scenarios'"
                  class="mr-3"
                  @input="selectScenario($event)"
                ></w-white-dropdown>
              </div> -->
              <!-- <div class="d-flex flex-row mr-5">
            <div class="sliderIcon" @click="handleAdditionSceario">
              <div>
                <i class="fas fa-plus px-2" :style="{ color: rangeColor }"></i>
              </div>
            </div>
          </div> -->
            </div>
          </div>
        </div>
      </div>

      <b-row>
        <b-col cols="12" md="4" :class="[expand ? 'd-none' : '']">
          <div class="card d-none">
            <div class="card-body p-0">
              <div class="card-title">Prediction</div>
              <div class="mt-5">
                <w-tab :tabName="activePredictionTab" :tabs="predictionTab" @activeTab="changePredictionTab($event)">
                </w-tab>
              </div>
              <b-container fluid class="slide-parent">
                <SalesGrowth v-show="activePredictionTab === 'Budget Based'" :sliderValue="sliderRevenueValue"
                  :data="revenueData" :bigRangeSize="true" :step="5" :handleColor="'#536dfe'"
                  @sliderChangeHandle="handleRevenueSlider" :sliderName="'Budget Growth'" :specialChar="''" />
                <SalesGrowth v-show="activePredictionTab === kpiMarket + ' Based'" :sliderValue="sliderKPIValue"
                  :data="kpiData" :bigRangeSize="true" :step="5" :handleColor="'#536dfe'"
                  @sliderChangeHandle="handleKPISlider" :sliderName="this.kpiMarket + ' Growth'" :specialChar="''" />
                <!--<SalesGrowth
                  v-if="activePredictionTab === 'Budget Based'"
                  :sliderValue="sliderBudgetValue"
                  :data="budgetData"
                  :bigRangeSize="true"
                  @sliderChangeHandle="handleBudgetSlider"
                />-->
                <div class="w-100 py-4" style="text-align: center; font-size: medium; color: red">
                  <span>{{ errorMessage }}</span>
                </div>
              </b-container>
            </div>
          </div>
          <div class="card mt-5 mb-5 mb-md-0 d-none">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-md-12">
                  <details class="">
                    <Summary class="Additional-details">Additional Details</Summary>
                    <b-container fluid>
                      <div v-for="(detail, index) in additionalDetails" :key="index"
                        class="row additonal-detail py-5 px-4" :class="[!detail.select ? 'border-top' : 'detail-bg']">
                        <b-col cols="10">
                          <div class="details-heading">{{ detail.name }}</div>
                          <div v-if="detail.select">
                            <label>Product</label>
                            <b-form-select v-model="detail.product" :options="detail.dropdownList"></b-form-select>
                            <label>Date</label>
                            <div>
                              <input type="date" :value="detail.date" class="date-input" />
                            </div>
                            <label>Value in numbers</label>
                            <b-form-input v-model="detail.value" type="number"></b-form-input>
                          </div>
                        </b-col>
                        <b-col cols="2">
                          <input :name="`checkbox-${index}`" type="checkbox" class="form-check-input"
                            :value="detail.select" @input="updateCheckbox(index, $event.target.checked)" />
                        </b-col>
                      </div>
                    </b-container>
                  </details>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="12" :md="expand ? 12 : 8">
          <div class="card mb-4 mt-5">
            <div class="card-body p-0">
              <div class="card-title">Prediction</div>
              <b-container fluid class="wrapper-statistics">
                <b-row>
                  <b-col cols="12" sm="6" :md="expand ? 2 : 4" v-for="(data, index) in predictionData" :key="index"
                    class="item">
                    <div class="item-img" :class="[
                      index === 0
                        ? 'blue-pastel'
                        : index === 1
                          ? 'green-pastel'
                          : index === 2
                            ? 'pink-pastel'
                            : index === 3
                              ? 'red-pastel'
                              : index === 4
                                ? 'yellow-pastel'
                                : index === 5
                                  ? 'purple-pastel'
                                  : 'blue-pastel',
                    ]">
                      <!-- <i class="fas fa-chart-pie" style="font-size: 24px"></i> -->
                      <img :src="require(`@/assets/Icons/${data.image}.svg`)" :alt="data.name" height="25px" />
                    </div>

                    <div class="item-text" v-if="data.name === 'Change'">
                      <div class="item-text__title">{{ data.name }}</div>
                      <div class="item-text__subtitle">{{ data.value }}</div>
                    </div>
                    <div class="item-text" v-else>
                      <div class="item-text__title">{{ data.name }}</div>
                      <div class="item-text__subtitle">
                        {{ orgnizeNumber(data.value) }} {{ data.symbol }}
                      </div>
                    </div>

                    <div v-if="
                      (!expand && (index + 1) % 3 !== 0) ||
                      (expand && (index + 1) % 6 !== 0)
                    " class="item-right-line"></div>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </div>
          <!--<div class="card mb-4">
            <div class="card-body p-3">
              <ColumnChart :chartData="columnChartData" />
            </div>
          </div>
          <div class="card mb-4">
            <div class="card-body pt-2">
              <Digital
                :data="digitalExpandData"
                :stripData="digitalData"
                subtitle="Recommended Digital Mix"
                @getDataBreakdown="getDigitalBreakdown"
                :showBreakdownIcon="false"
                total=""
              />
            </div>
          </div>-->

          <div>
            <div class="card mb-4 w-100" v-for="(optimized, index) in optimizedData" :key="index">
              <div class="card-body pt-2 w-100" style="overflow: auto">
                <Content :stripData="optimized.stripData" :subtitle="'Forecasted Quotes'" total="" :title="'Media'"
                  @chartClick="getMediaBreakDown($event)" :barData="optimized.expandData"
                  :levelTwoBarData="optimized.expandLevelTwoData" :showBreakdownIcon="true"
                  :breakeDownShow="optimized.breakDown" class="m-0" ref="contentBlock" :showLegend="true"
                  @getDataBreakdown="getExpandData($event, index)" />
              </div>
            </div>
          </div>
          <w-table class="d-none" :tableProperties="tableDataValues" :tableLoading="tableLoding" :showHeading="true"
            :headingText="'Optimization History'" :customRowColor="true" @retryModel="restartModel($event)"
            :noDataText="'No Model found'" @viewModelResult="viewModelResult($event)">
          </w-table>
          <!--<div class="card">
            <div class="card-body p-3">
              <LineChart :chartData="lineChartData" />
            </div>
          </div>-->
        </b-col>
      </b-row>
      <!-- <page-loader v-if="countApi == countAllApi"></page-loader> -->
    </div>
  </div>
</template>

<script>
// import PageLoader from "@/widgets/PageLoader.vue";
import Tab from "@/components/Solutions/Tab.vue";
import SalesGrowth from "@/components/Solutions/AISalesGrowth.vue";
// import Digital from "@/components/Solutions/Digital.vue";
import Content from "@/components/Solutions/ActualDataContent.vue";
// import LineChart from "@/components/Chart/LineChart.vue";
// import ColumnChart from "@/components/Chart/ColumnChart.vue";
import Toast from "@/components/Toast/Toast.vue";
import Button from "@/components/Profile/Button.vue";
// import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
import DataTable from "@/widgets/DataTable.vue";

import { AimoUnileverServices } from "@/services/AimoUnileverServices.js";
const aimoUnileverService = new AimoUnileverServices();

// import { DigitalPoemServices } from "@/services/DigitalPoemServices.js";
// const digitalPoemService = new DigitalPoemServices();
// import AgilePlanningResult from "./AgilePlanningResults.vue";
// import { AIDigitalPoem } from "../../../services/AIDigitalPoemServices";
// const aiDigitalPoem = new AIDigitalPoem();
import "@/mixins/SortNumber.js";
import moment from "moment";
import { abbreviateNumber } from "js-abbreviation-number";

export default {
  name: "PlanningResults",
  components: {
    "w-tab": Tab,
    "w-button": Button,
    SalesGrowth,
    // "w-white-dropdown": WhiteDropdown,
    "w-table": DataTable,
    // Digital,
    Content,
    // LineChart,
    // ColumnChart,
    Toast,
    // PageLoader,
    // "w-agile-planning-result": AgilePlanningResult,
  },
  data() {
    return {
      optimizeDataTEMP: JSON.parse(localStorage.getItem("optimizeData")),
      tableLoding: true,
      tableDataValues: {
        fields: [
          {
            key: "Started",
            label: "Started",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "Brand",
            label: "Brand",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "KPI",
            label: "KPI",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "Total Budget",
            label: "Total Budget",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "Progress",
            label: "Progress",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "Status",
            label: "Status",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "actionRowUL",
            label: "Action",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
        ],
        items: [],
      },
      selectedScenario: { id: "", text: "" },
      isForecastActive: "",
      brand: localStorage.getItem("selectedBrandKPI"),
      kpi: "Overall",
      brandDropdown: [
        { text: "Tresemme", id: "tresemme" },
        { text: "Dove", id: "dove" },
      ],
      scenarioDropdown: [],
      totalBudget: 0,
      forecastingDataOptimized: "",
      budgetDataOptimized: "",
      isLoading: false,
      errorMessage: "",
      showBlock: "planningResult",
      countApi: 0,
      market: "",
      expand: true,
      kpiMarket: "",
      saveOptimizedResult: {
        runId: "",
        planName: "",
        date: moment(new Date()).format("YYYY-MM-DD"),
        kpiConfig: "",
        values: {
          growth: "",
          kpiSelection: {},
          mediaData: [],
          optimised_output: {},
          target: "",
        },
      },
      optimizedData: [
        {
          title: "",
          stripData: [],
          expandData: {
            data: [
              {
                name: "Growth",
                data: [],
              },
            ],
            xAxis: {
              categories: [],
              crosshair: true,
            },
            yAxis: {
              min: 0,
              title: {
                text: "Growth",
              },
            },
            plotOptions: {
              column: {
                pointPadding: 0.4,
                borderWidth: 0,
              },
              series: {
                borderRadiusTopLeft: 25,
                borderRadiusTopRight: 25,
              },
            },
            subtitle: {},
            legend: {},
            color: ["#40c4ff", "#536dfe"],
            tooltip: {
              headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
              pointFormat:
                '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
              footerFormat: "</table>",
              shared: true,
              useHTML: true,
              shadow: false,
              style: {
                padding: 0,
              },
            },
          },
          allExpandData: [],
          breakDown: false,
        },
      ],
      // predictionTab: [
      //   {
      //     category: "Budget Based",
      //   },
      //   {
      //     category: this.kpiMarket+" Based",
      //   },
      // ],
      digitalExpandData: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60, 30],
          },
          {
            name: "RIO",
            data: [50, 30, 50, 50],
          },
        ],
        xAxis: {
          categories: ["Instagram", "Facebook", "Youtube", "Twitter"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        subtitle: {},
        legend: {},
        color: ["#40c4ff", "#536dfe"],
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      activePredictionTab: "Budget Based",
      sliderRevenueValue: 16,
      sliderKPIValue: 0,
      revenueData: [{ name: "Expected Revenue", value: 621 }],
      kpiData: [{ name: "Expected Budget", value: 621 }],
      sliderBudgetValue: 46,
      budgetData: [{ name: "Expected Budget", value: 621 }],
      predictionData: [],
      lineChartData: {
        colors: ["#050505", "#18ffff"],
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
          formatter: function () {
            const x = this.x;
            var value = this.series.data.find((obj) => obj.category === x).options.y;
            if (this.series.name) {
              return ` ${this.series.name}
              : <b>
            ${value}
              </b>`;
            } else {
              return value;
            }
          },
        },
        series: [
          {
            data: [1, 2, 3, 3, 2, 1, 4, 5, 3, 7, 9, 10],
            marker: {
              symbol: "circle",
              radius: 10,
              // status: {
              //   hover: {
              //     enabled: true,
              //   },
              // },
            },
            type: "spline",
            name: "Actual",
          },
          {
            data: [3, 2, 1, 2, 1, 4, 1, 2, 3, 3, 9, 10],
            marker: {
              symbol: "circle",
              radius: 10,
            },
            type: "spline",
            name: "Predicted",
          },
        ],
        title: {
          text: "Media - Previous vs Predicted Budget",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {
          text: "Recommended Budget Mix",
          align: "left",
          y: 60,
          style: {
            color: "#8394b4",
            fontSize: "20px",
            fontFamily: "ProximaNovaRegular",
          },
        },
        legend: {
          align: "left",
          verticalAlign: "bottom",
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 40,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        yAxis: {
          title: {
            text: "sales growth %",
            style: {
              color: "#8394b4",
            },
            y: 12,
          },
        },
        xAxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "June",
            "July",
            "August",
            "Sept",
            "October",
            "November",
            "December",
          ],
        },
      },
      additionalDetails: [
        {
          name: "Product",
          product: "",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          select: false,
          date: "",
          value: "",
        },
        {
          name: "Distribution",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          select: false,
          product: "",
          date: "",
          value: "",
        },
        {
          name: "Consumer Promo",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          select: false,
          product: "",
          date: "",
          value: "",
        },
        {
          name: "Advertising",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          select: false,
          product: "",
          date: "",
          value: "",
        },
      ],
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      digitalThirdLevelData: [],
      contentThirdLevelData: [],
      contentThirdLevelAgileData: [],
      digitalThirdLevelAgileData: [],
      levelOneData: [],
      colors: [
        "#f77f00",
        "#1877f2",
        "#148f3a",
        "#0077b5",
        "#dd2a7b",
        "#08a0e9",
        "#1877f2",
        "#148f3a",
        "#0077b5",
        "#dd2a7b",
      ],
      digitalData: [],
      currentTab: "",
      fromHistoryTable: sessionStorage.getItem("fromHistoryTable"),
      fromScenarioPlanning: sessionStorage.getItem("fronScenarioPlanning"),
    };
  },
  mounted() {
    const optimizedBudgetData = this.$router.history.current.params.optimizedBudgetData;
    this.budgetDataOptimized = optimizedBudgetData;

    const forecastedBudgetData = this.$router.history.current.params
      .forecastedOptimizedData;

    this.forecastingDataOptimized = forecastedBudgetData;

    // const allScenarioDetails = this.$router.history.current.params.scenarioDetails;

    const allScenarioDetails = JSON.parse(sessionStorage.getItem("forecastingDropdown"));

    const optimizationBudget = this.$router.history.current.params.budget;
    this.totalBudget = optimizationBudget;

    this.scenarioDropdown = allScenarioDetails;

    // this.selectedScenario = this.$router.history.current.params.selectedScenario;

    this.selectedScenario = JSON.parse(sessionStorage.getItem("selectedForecast"));

    if (sessionStorage.budgetOptimizer === "") {
      this.isLoading = localStorage.true;
    }
  },
  // watch: {
  //   name(newName) {
  //     localStorage.name = newName;
  //   }
  // },
  methods: {
    handleDownload() {
      let kpi = localStorage.getItem("selectedMediaKPI");
      let brand = localStorage.getItem("selectedBrandKPI");
      let data = JSON.parse(sessionStorage.getItem("obbData"));
      let optimizeData = JSON.parse(localStorage.getItem("optimizeData"));
      let runId = optimizeData.runId;
      const filename = `${brand} ${kpi} ${+data.month} Months`;
      let temp = [];

      let curr = data.budget / +data.month;

      for (var j = 0; j < +data.month; j++) {
        temp = temp.concat(curr);
      }

      this.toastData = {
        show: "true",
        type: "success",
        message: "Your download is in progress",
      };
      let clientId = sessionStorage.getItem("subId");

      aimoUnileverService
        .downloadOptimizationData(
          {
            download: true,
            runId: runId,
            clientId: clientId,
            duration: +data.month,
            budget: temp,
            brand: localStorage.getItem("selectedBrandKPI"),
            kpi: "Overall",
            constraints: [],
          },
          filename
        )
        .then(() => {
          this.toastData = {
            show: "true",
            type: "success",
            message: "succesfully downloaded file",
          };
        })
        .catch((err) => {
          this.toastData = {
            show: "true",
            type: "",
            message: err,
          };
        });
    },
    handleCreateScenario() {
      this.$router.push("/solutions/ul/bbo-scenario-planning");
    },
    viewModelResult(e) {
      let clientId = sessionStorage.getItem("subId")
      aimoUnileverService.viewResultFromHistory(e._id, clientId).then((res) => {
        localStorage.setItem("optimizeData", JSON.stringify(res));
        this.$router.push("/solutions/ul/scenario-planning/planning-results");
        // localStorage.setItem("optimizeData", res)
      });
    },
    getOptimizationHistoryTable() {
      this.tableLoding = true;
      var clientId = sessionStorage.getItem("subId");
      aimoUnileverService.getSummaryTableData(clientId).then((res) => {
        this.tableLoding = false;
        this.tableDataValues.items = [];
        this.tableDataValues.items = res;
      });
    },
    fetchScenarioData(e) {
      aimoUnileverService
        .getForecastingResults({
          runId: e.id,
          brand: localStorage.getItem("selectedBrandKPI"),
          clientId: sessionStorage.getItem("subId"),
          kpi: "Overall",
        })
        .then((res) => {
          localStorage.setItem("optimizeData", JSON.stringify(res));
          this.getAIPlanningResult();
        });
    },
    selectScenario(e) {
      this.selectedScenario = e;
      if (e.text !== undefined) {
        this.fetchScenarioData(e);
      }
    },
    getMediaBreakDown(e) {
      const res = JSON.parse(localStorage.getItem("optimizeData"));

      const selectedChannel = res.mediaData[0].levelThreeList.filter((item) =>
        item.name.includes(e.text)
      );

      this.optimizedData[0].expandLevelTwoData.data[0].data = [];
      this.optimizedData[0].expandLevelTwoData.data[1].data = [];
      this.optimizedData[0].expandLevelTwoData.xAxis.categories = [];

      for (var i = 0; i < selectedChannel[0].value.length; i++) {
        console.log(selectedChannel[0].value[i].previous);
        this.optimizedData[0].expandLevelTwoData.data[0].data.push(
          selectedChannel[0].value[i].previous
        );
        this.optimizedData[0].expandLevelTwoData.data[1].data.push(
          selectedChannel[0].value[i].optimised
        );

        this.optimizedData[0].expandLevelTwoData.xAxis.categories.push(
          selectedChannel[0].value[i].name
        );
      }
    },
    // saveResult(e) {
    //   this.saveOptimizedResult.planName = e;
    //   this.saveOptimizedResult.kpiConfig = sessionStorage.getItem("kpiConfig");
    //   aiDigitalPoem
    //     .saveOptimizationResult(this.saveOptimizedResult)
    //     .then((res) => {
    //       if (res === "Duplicate data entry") {
    //         this.toastData.show = true;
    //         this.toastData.type = "failed";
    //         this.toastData.message =
    //           "This result is allready saved in our system";
    //       } else if (res === "Plan Name already exists") {
    //         this.toastData.show = true;
    //         this.toastData.type = "error";
    //         this.toastData.message = "This name is allready exists";
    //       } else {
    //         this.updatePlan();
    //       }
    //     });
    // },
    showAgile() {
      this.showBlock = "agile";
    },
    handleBudgetSlider(e) {
      this.sliderBudgetValue = e;
    },
    handleRevenueSlider(e) {
      if (e > 41) {
        this.errorMessage = "Value must be in between 0-40";
        return;
      }
      this.errorMessage = "";
      this.sliderRevenueValue = e;
      this.countApi = 1;
      sessionStorage.setItem("actualGrowth", e);
      sessionStorage.setItem("edited", "notEdited");
      this.getAIPlanningResult();
    },
    handleKPISlider(e) {
      if (e > 41) {
        this.errorMessage = "Value must be in between 0-40";
        return;
      }
      this.errorMessage = "";
      this.sliderKPIValue = e;
      this.countApi = 1;
      sessionStorage.setItem("actualGrowth", e);
      sessionStorage.setItem("edited", "notEdited");
      this.getAIPlanningResult();
    },
    changePredictionTab(tabName) {
      if (tabName === this.activePredictionTab) {
        return;
      }

      this.activePredictionTab = tabName;
      sessionStorage.setItem("edited", "notEdited");
      if (tabName === "Budget Based") {
        sessionStorage.setItem("targetVariable", "budget");
      } else if (tabName === this.kpiMarket + " Based") {
        sessionStorage.setItem("targetVariable", "KPI");
      }
      this.getAIPlanningResult();
    },
    toggleExpand() {
      this.expand = !this.expand;
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 100);
    },
    updateCheckbox(index, val) {
      this.additionalDetails[index].select = val;
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    updatePlan() {
      this.toastData = {
        show: true,
        type: "success",
        message: "Yay, our system has saved your plan successfully",
      };
    },
    gotoScenarioPlan() {
      if (this.optimizedData[0].stripData.length === 0) {
        return;
      }
      this.$router.push("/solutions/ul/bbo-scenario-planning");
    },
    getAIPlanningResult() {
      var color = [
        "#6a1b9a",
        "#8e24aa",
        "#ab47bc",
        "#ba68c8",
        "#ce93d8",
        "#e1bee7",
        "#e1bee7",
        "#e1bee7",
        "#e1bee7",
        "#e1bee7",
        "#e1bee7",
        "#e1bee7",
        "#e1bee7",
        "#e1bee7",
      ];

      // aiDigitalPoem
      //   .getExpectedRevenue("202112-2310-0322-7f7a-4_ykbfqzxtqdiclezqnauk", "5", "budget")
      //   .then(() => {
      var res1 = JSON.parse(localStorage.getItem("optimizeData"));
      var res;
      if (
        sessionStorage.getItem("edited") === "edited" &&
        this.$route.params.response !== undefined
      ) {
        res = this.$route.params.response;
      } else {
        res = res1;
      }
      this.saveOptimizedResult.runId = sessionStorage.getItem("subId");
      // this.saveOptimizedResult.values.growth = res.response.result.growth;
      // this.saveOptimizedResult.values.kpiSelection =
      //   res.response.result.kpiSelection;

      this.saveOptimizedResult.values.mediaData = res.mediaData;
      this.saveOptimizedResult.values.optimised_output = res.optimised_output;
      this.saveOptimizedResult.values.target = sessionStorage.getItem(
        "targetVariable"
      );
      this.predictionData = [];
      // console.log(res.response.result.kpiSelection.previous)
      if (this.currentTab === "forecasting") {
        this.predictionData.push({
          name: res.predictions[0].name,
          value: res.predictions[0].value,
          image: "Last year Revenue",
          symbol: "",
        });
        this.predictionData.push({
          name: "Forecasted Budget",
          value: res.predictions[1].value,
          image: "Predicted Budget",
          symbol: "",
        });
        // this.predictionData.push({
        //   name: "Previous Quotes",
        //   value: res.predictions[2].value,
        //   image: "Predicted Budget",
        //   symbol: "",
        // });
        this.predictionData.push({
          name: res.predictions[3].name,
          value: res.predictions[3].value,
          image: "Predicted Budget",
          symbol: "",
        });
      } else if (this.currentTab === "bbo") {
        // this.predictionData.push({
        //   name: "Previous Budget",
        //   value: res.predictions[0].value,
        //   image: "Last year Revenue",
        //   symbol: "",
        // });
        this.predictionData.push({
          name: "Previous Quotes",
          value: res.predictions[2].value,
          image: "Predicted Budget",
          symbol: "",
        });
        this.predictionData.push({
          name: "Expected Quotes",
          value: res.predictions[3].value,
          image: "Predicted Budget",
          symbol: "",
        });
        // this.predictionData.push({
        //   name: "Previous MIR",
        //   value: res.predictions[2].value,
        //   image: "Expected Revenue",
        //   symbol: "",
        // });
        this.predictionData.push({
          name: "Previous Budget",
          value: res.predictions[0].value,
          image: "Predicted Budget",
        });
        this.predictionData.push({
          name: "Optimised Budget",
          value: res.predictions[1].value,
          image: "Expected Revenue",
          symbol: "",
        });
        // this.predictionData.push({
        //   name: "Change in Revenue",
        //   value: res.predictions[4].value,
        //   image: "Expected Revenue",
        //   symbol: "%",
        // });
        // this.predictionData.push({
        //   name: "Change in Budget",
        //   value: res.predictions[5].value,
        //   image: "Predicted Budget",
        //   symbol: "%",
        // });
        // this.predictionData.push({
        //   name: "Previous ROI",
        //   value: res.predictions[6].value,
        //   image: "Last year Revenue",
        // });
      }
      // this.predictionData.push({
      //   name: "Change",
      //   value:
      //     this.orgnizeNumber(res.response.result.kpiSelection.variance) +
      //     "%",
      //   image: "Variance",
      // });
      // this.predictionData.push({
      //   name: "Previous Budget",
      //   value: res.response.result.optimised_output.previous,
      //   image: "Previous Budget",
      // });
      // this.predictionData.push({
      //   name: "Optimized Budget",
      //   value: res.response.result.optimised_output.optimized,
      //   image: "Predicted Budget",
      // });
      // this.predictionData.push({
      //   name: "Change",
      //   value:
      //     this.orgnizeNumber(
      //       res.response.result.optimised_output.variance
      //     ) + "%",
      //   image: "Variance (2)",
      // });
      this.optimizedData = [];
      console.log("res.mediaData.length", res.mediaData);
      for (var i = 0; i < res.mediaData.length; i++) {
        var total = 0;
        for (var k = 0; k < res.mediaData[i].levelOne.length; k++) {
          total += res.mediaData[i].levelOne[k].previous;
        }
        var stripData = [];
        var reaarrangeData = this.rearrangeArray(
          res.mediaData[i].levelOne,
          res.mediaData[i].levelOne.length
        );
        for (var j = 0; j < reaarrangeData.length; j++) {
          var per = Math.round((reaarrangeData[j].optimised * 100) / total);
          if (per < 5) {
            per = 5;
          }
          stripData.push({
            label: reaarrangeData[j].name,
            value: this.orgnizeNumber(reaarrangeData[j].previous),
            actualValue: reaarrangeData[j].optimised,
            backgroundColor: color[j],
            percentage: per,
            previous: reaarrangeData[j].previous,
          });

          if (j === res.mediaData[i].levelOne.length - 1) {
            console.log(sessionStorage.getItem("currentTab"));
            if (sessionStorage.getItem("currentTab") === "forecasting") {
              this.optimizedData.push({
                title: res.mediaData[i].title,
                stripData: stripData,
                expandData: {
                  data: [
                    {
                      name: "Forecasted Quotes ",
                      data: [],
                    },
                    {
                      name: "Planned Budget",
                      data: [],
                    },
                  ],
                  xAxis: {
                    categories: [],
                    crosshair: true,
                  },
                  yAxis: {
                    min: 0,
                    title: {
                      text: "Optimized " + this.kpiMarket,
                    },
                  },
                  plotOptions: {
                    column: {
                      pointPadding: 0.4,
                      borderWidth: 0,
                    },
                    series: {
                      borderRadiusTopLeft: 25,
                      borderRadiusTopRight: 25,
                    },
                  },
                  subtitle: {},
                  legend: {},
                  color: ["#40c4ff", "#536dfe"],
                  tooltip: {
                    headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                    pointFormat:
                      '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                      '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                    footerFormat: "</table>",
                    shared: true,
                    useHTML: true,
                    shadow: false,
                    style: {
                      padding: 0,
                    },
                  },
                },
                expandLevelTwoData: {
                  data: [
                    {
                      name: "Forecasted Quotes",
                      data: [],
                    },
                    {
                      name: "Planned Budget",
                      data: [],
                    },
                  ],
                  xAxis: {
                    categories: [],
                    crosshair: true,
                  },
                  yAxis: {
                    min: 0,
                    title: {
                      text: "Optimized Budget",
                    },
                  },
                  plotOptions: {
                    column: {
                      pointPadding: 0.4,
                      borderWidth: 0,
                    },
                    series: {
                      borderRadiusTopLeft: 25,
                      borderRadiusTopRight: 25,
                    },
                  },
                  subtitle: {},
                  legend: {},
                  color: ["#85edff", "#b5a9ff"],
                  tooltip: {
                    headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                    pointFormat:
                      '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                      '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                    footerFormat: "</table>",
                    shared: true,
                    useHTML: true,
                    shadow: false,
                    style: {
                      padding: 0,
                    },
                  },
                },
                breakDown: false,
                allExpandData: res.mediaData[i].levelTwo,
              });
            } else if (this.currentTab === "bbo") {
              this.optimizedData.push({
                title: res.mediaData[i].title,
                stripData: stripData,
                expandData: {
                  data: [
                    {
                      name: "Expected Quotes",
                      data: [],
                    },
                    {
                      name: "Optimized Budget",
                      data: [],
                    },
                  ],
                  xAxis: {
                    categories: [],
                    crosshair: true,
                  },
                  yAxis: {
                    min: 0,
                    title: {
                      text: "Optimized Budget",
                    },
                  },
                  plotOptions: {
                    column: {
                      pointPadding: 0.4,
                      borderWidth: 0,
                    },
                    series: {
                      borderRadiusTopLeft: 25,
                      borderRadiusTopRight: 25,
                    },
                  },
                  subtitle: {},
                  legend: {},
                  color: ["#40c4ff", "#536dfe"],
                  tooltip: {
                    headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                    pointFormat:
                      '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                      '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                    footerFormat: "</table>",
                    shared: true,
                    useHTML: true,
                    shadow: false,
                    style: {
                      padding: 0,
                    },
                  },
                },
                expandLevelTwoData: {
                  data: [
                    {
                      name: "Expected Quotes",
                      data: [],
                    },
                    {
                      name: "Optimized Budget",
                      data: [],
                    },
                  ],
                  xAxis: {
                    categories: [],
                    crosshair: true,
                  },
                  yAxis: {
                    min: 0,
                    title: {
                      text: "Optimized Budget",
                    },
                  },
                  plotOptions: {
                    column: {
                      pointPadding: 0.4,
                      borderWidth: 0,
                    },
                    series: {
                      borderRadiusTopLeft: 25,
                      borderRadiusTopRight: 25,
                    },
                  },
                  subtitle: {},
                  legend: {},
                  color: ["#85edff", "#b5a9ff"],
                  tooltip: {
                    headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                    pointFormat:
                      '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                      '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                    footerFormat: "</table>",
                    shared: true,
                    useHTML: true,
                    shadow: false,
                    style: {
                      padding: 0,
                    },
                  },
                },
                breakDown: false,
                allExpandData: res.mediaData[i].levelTwo,
              });
            }
          }
        }
        this.$refs.contentBlock[i].updateBreakdown(false);
      }
      // this.revenueData = [
      //   {
      //     name: "Expected Revenue",
      //     value: this.orgnizeNumber(res.kpiSelection.expected),
      //   },
      // ];
      // this.kpiData = [
      //   {
      //     name: "Target " + this.kpiMarket,
      //     value: this.orgnizeNumber(
      //       res.response.kpiSelection.expected
      //     ),
      //   },
      // ];
      // });
    },
    getExpandData(e, index) {
      this.optimizedData[index].expandData.data[0].data = [];
      this.optimizedData[index].expandData.data[1].data = [];
      this.optimizedData[index].expandData.xAxis.categories = [];
      this.optimizedData[index].breakDown = true;
      var expandDataObj = this.optimizedData[index].allExpandData.find(
        (obj) => obj.name.toLowerCase() === e.label.toLowerCase()
      ).value;
      console.log(expandDataObj);
      if (expandDataObj !== undefined) {
        for (var i = 0; i < expandDataObj.length; i++) {
          this.optimizedData[index].expandData.data[0].data.push(
            expandDataObj[i].previous
          );
          this.optimizedData[index].expandData.data[1].data.push(
            expandDataObj[i].optimised
          );
          this.optimizedData[index].expandData.xAxis.categories.push(
            expandDataObj[i].name
          );
        }
      }
    },
  },
  created() {
    this.currentTab = sessionStorage.getItem("currentTab");
    this.sliderRevenueValue = parseInt(sessionStorage.getItem("actualGrowth"));
    this.kpiMarket = sessionStorage.getItem("kpiConfig");
    this.sliderKPIValue = this.sliderRevenueValue;
    if (sessionStorage.getItem("targetVariable") === "budget") {
      this.activePredictionTab = "Budget Based";
    } else if (sessionStorage.getItem("targetVariable") === "KPI") {
      this.activePredictionTab = this.kpiMarket + " Based";
    }
    this.getAIPlanningResult();
    this.$nextTick(function () {
      window.setInterval(() => {
        // this.getOptimizationHistoryTable();
      }, 10000);
    });
  },
  computed: {
    predictionTab() {
      return [
        {
          category: "Budget Based",
        },
        {
          category: this.kpiMarket + " Based",
        },
      ];
    },
    orgnizeNumber() {
      return (data) => {
        if (data > 999) {
          return abbreviateNumber(data, 1);
        } else if (data < 0) {
          var posNumber = Math.abs(data);
          return "-" + abbreviateNumber(posNumber, 1);
        } else {
          return Math.round(data * 10) / 10;
        }
      };
    },
  },
};
</script>
<style>
.sliderIcon {
  width: 50px;
  height: 50px;
  margin-top: 10px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  background-color: #fff;
}

.sliderIcon i {
  font-size: 20px;
}

.slide-parent .rs-handle.rs-move {
  border: 11px solid #fff;
}
</style>

<style scoped>
.card {
  box-shadow: 0 0 8px 0 #c9ced5;
}

.card-title {
  margin: 24px 0px 0px 24px;
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}

.arrow-card {
  width: 74px;
  height: 74px;
  padding: 25px;
  box-shadow: 0 0 8px 0 #c9ced5;
}

.arrow-card-collapse {
  background-color: black;
  left: 0;
  margin-top: 0px;
}

.arrow {
  font-size: 18px;
}

.arrow-white {
  color: #fff;
  font-size: 18px;
}

.btn-group {
  margin-bottom: 31px;
}

.btn-effect-outline {
  padding: 6px;
  border-radius: 5px;
  border: solid 2px #050505;
  font-family: ProximaNovaBold;
  font-size: medium;
  color: #222a37 !important;
  background-color: transparent !important;
}

.btn-effect-outline:hover {
  color: #fff !important;
}

.wrapper-statistics {
  padding: 40px 24px;
}

.item {
  display: flex;
  margin: 20px 0px;
  justify-content: center;
}

/* .item:not(:nth-child(3n)):before {
  content: "";
  position: absolute;
  height: 40px;
  width: 1px;
  background: #e8edf5;
  right: 20px;
  top: 8px;
} */

.item-right-line {
  position: absolute;
  height: 40px;
  width: 1px;
  background: #e8edf5;
  right: 20px;
}

.item-img {
  background: #67e8ff;
  border-radius: 50%;
  padding: 2rem;
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.item-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
}

.item-text__title {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8394b4;
  padding-bottom: 1rem;
}

.item-text__subtitle {
  font-family: ProximaNovaBold;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
}

.blue-pastel {
  background: #ff8a80;
}

.green-pastel {
  background: #ff80ab;
}

.pink-pastel {
  background: #64ffda;
}

.red-pastel {
  background: #ffd740;
}

.yellow-pastel {
  background: #40c4ff;
}

.purple-pastel {
  background: #ea80fc;
}

.Additional-details {
  width: 259px;
  margin: 1px 986px 2px 0;
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
  padding: 28px 24px;
}

.details-heading {
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}

.form-check-input {
  height: 24px;
  position: relative;
  top: 0px;
  margin: 0px;
  margin-right: 10px;
}

.form-check-input:checked {
  background-color: #222a37;
  border-color: #222a37;
}

label {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #9aafd4;
  margin-top: 37px;
}

input[type="number"] {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  box-shadow: none;
  color: #222a37;
  font-family: ProximaNovaBold;
  font-size: 16px;
  border-radius: 0;
  background-color: #eff5ff;
}

input[type="date"] {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  box-shadow: none;
  color: #222a37;
  font-family: ProximaNovaBold;
  font-size: 16px;
  border-radius: 0;
  width: 100%;
  background-color: #eff5ff;
}

select {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  box-shadow: none;
  color: #222a37;
  font-family: ProximaNovaBold;
  font-size: 16px;
  border-radius: 0;
  padding-left: 0;
  background-color: #eff5ff;
}

option {
  color: #222a37;
  font-family: ProximaNovaRegular;
  font-size: 16px;
}

select:focus {
  border: none;
  border-bottom: 1px solid black;
  box-shadow: none;
  outline: none;
}

.detail-bg {
  background-color: #eff5ff;
}
</style>
